.trainingContainer {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.trainingContainer h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.trainingContainer p {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.map {
  width: 100%;
  height: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.locationList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.locationList li {
  font-size: 1.1rem;
  margin: 0.5rem 0;
}

.counter {
  text-align: center;
  margin: 2rem 0;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.counter h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.counterNumber {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0072ff;
}

.counterSubtext {
  font-size: 0.9rem; /* Smaller text size */
  color: #666; /* Softer gray for secondary emphasis */
  text-align: center;
  margin-top: 0.5rem; /* Space between number and subtext */
  font-style: italic; /* Optional: Italicize the text */
}
