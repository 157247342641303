/* Apply fonts to all pages */
body {
  font-family: 'Source Sans Pro', sans-serif; /* Clean sans-serif for body text */
  background-color: #f9f9f9; /* Light background */
  color: #333; /* Neutral dark gray text */
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

/* Headers and titles */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif; /* Elegant serif for headings */
  color: #000; /* Black for emphasis */
  margin-bottom: 1rem;
}

/* Paragraphs and other text */
p, span, a, li {
  font-family: 'Source Sans Pro', sans-serif; /* Consistent sans-serif font */
  font-size: 1rem;
  color: #444; /* Slightly lighter gray */
}

.projectSubText {
  font-weight: 400 !important;
}

/* Links */
a {
  color: #0072ff; /* Accent blue for links */
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #0056b3; /* Darker blue on hover */
}

/* Buttons */
button {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 1rem;
}

/* Adjust spacing for headlines */
h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.6rem;
}
