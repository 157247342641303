/* Container for the entire page */
.homeContainer {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 2rem 1rem; /* Add padding for spacing */
  max-width: 1200px; /* Limit max width for readability */
  margin: 0 auto; /* Center the content */
}

/* Hero Section */
.heroSection {
  display: flex; /* Align image and text side by side */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  align-items: center;
  gap: 2rem; /* Space between image and text */
  margin-bottom: 3rem;
}

.profileImage {
  width: 150px; /* Smaller size */
  height: 150px;
  border-radius: 50%; /* Circle shape */
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  flex-shrink: 0; /* Prevent image from shrinking */
}

.introText {
  flex: 1; /* Make the text take the remaining space */
  min-width: 280px; /* Ensure text doesn’t get too small */
}

.introText h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.introText p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
  .heroSection {
    flex-direction: column; /* Stack image and text vertically */
    text-align: center; /* Center-align text for smaller screens */
  }

  .profileImage {
    margin: 0 auto; /* Center the image */
  }

  .introText {
    min-width: unset;
  }
}

/* Work grid container */
.workGrid {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  gap: 1.5rem; /* Space between items */
  justify-content: center; /* Center-align the grid items */
  padding: 1rem 0;
}

/* Individual work item */
.workItem {
  text-decoration: none;
  color: inherit;
  width: calc(100% - 2rem); /* Default width for small screens */
  max-width: 300px; /* Limit width for larger screens */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.workItem:hover {
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

/* Ensure no zoom effect on images */
.workItem img {
  width: 100%; /* Ensure the image is responsive */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline spacing */
  transition: none; /* Remove image hover effect */
}

.workItem p {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  padding: 0.8rem;
  background-color: #f9f9f9;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .workItem {
    width: 100%; /* Full width for smaller screens */
  }
}

/* Project title */
.workItem .projectTitle {
  font-size: 1.2rem; /* Slightly smaller title */
  font-weight: bold !important; /* Bold for emphasis */
  color: #333 !important; /* Darker text */
  text-align: center !important;
  margin: 0 !important; /* Remove extra margin */
  line-height: 1.2 !important; /* Slight spacing between title and description */
}

/* Project subtext (description) */
.workItem .projectSubText {
  font-size: 0.85rem !important; /* Smaller, secondary text */
  font-weight: normal !important; /* Not bold */
  color: #666 !important; /* Softer gray for description */
  text-align: center !important;
  margin: 0 !important; /* No extra margin */
  line-height: 1.4 !important; /* Proper line spacing for readability */
}

/* Call-to-Action Section */
.ctaSection {
  text-align: center;
  margin: 3rem 0;
  padding: 2rem;
  background-color: #f9f9f9; /* Light gray background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.ctaSection h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.ctaSection p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.ctaLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.ctaLink {
  font-size: 1rem;
  color: #0072ff; /* Blue text for links */
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.ctaLink:hover {
  color: #0056b3; /* Darker blue on hover */
}
