.community-notes {
  padding: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
}

.metrics-table, .notes-table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
}

.metrics-table th, .metrics-table td,
.notes-table th, .notes-table td {
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.community-notes {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 2rem;
  max-width: 1200px;
  margin: auto;
  background: #ffffff;
}

h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #222;
}

p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 2rem;
}

.big-numbers {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.big-numbers > div {
  flex: 1;
  min-width: 200px;
  padding: 1rem;
  background-color: #f2f6ff;
  border: 1px solid #d3e0ff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.big-numbers h3 {
  margin-bottom: 0.5rem;
  color: #003087;
  font-size: 1.1rem;
}

.big-numbers p {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  color: #000;
}

.metrics-table, .notes-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.metrics-table th, .notes-table th,
.metrics-table td, .notes-table td {
  border: 1px solid #ddd;
  padding: 0.75rem;
  text-align: left;
  font-size: 0.95rem;
}

.metrics-table th, .notes-table th {
  background-color: #f0f0f0;
  color: #333;
}

.notes-table a {
  color: #0072ff;
  text-decoration: none;
}

.notes-table a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .big-numbers {
    flex-direction: column;
  }
}

