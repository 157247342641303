.navbar {
    background-color: #ffffff; /* White background */
    border-bottom: 1px solid #ddd; /* Thin line to separate navbar */
    padding: 1rem 2rem; /* Add padding for spacing */
    display: flex;
    justify-content: center; /* Center the links */
    align-items: center;
    position: sticky; /* Keeps navbar fixed at the top */
    top: 0;
    z-index: 1000; /* Ensures it stays above content */
  }
  
  .navbar-links {
    display: flex;
    gap: 2rem; /* Space between links */
  }
  
  .navbar-links a {
    text-decoration: none; /* Remove underline */
    color: #000; /* Black text */
    font-size: 1rem; /* Adjust font size */
    font-weight: 500; /* Slightly bold */
    transition: color 0.3s ease; /* Smooth hover effect */
  }
  
  .navbar-links a:hover {
    color: #0072ff; /* Highlight color on hover */
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .navbar {
      padding: 0.8rem 1rem; /* Adjust padding for smaller screens */
    }
  
    .navbar-links {
      gap: 1rem; /* Reduce space between links */
    }
  
    .navbar-links a {
      font-size: 0.9rem; /* Slightly smaller font size */
    }
  }
  