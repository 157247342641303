.projectsContainer {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
}

.description {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #555;
}

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.projectCard {
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 1.5rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.projectCard:hover {
  transform: translateY(-5px); /* Slight movement effect */
  transform: translateY(-5px) !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.projectTitle {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #222;
}

.projectDescription {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.projectLink {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.projectLink:hover {
  text-decoration: underline;
}

.projectImage {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Match the card shadow */
}

/* Make the entire project card clickable */
.projectCardLink {
  text-decoration: none; /* Remove default link styling */
  color: inherit; /* Inherit text color */
}

.projectCardLink:hover .projectCard {
  transform: translateY(-5px); /* Ensure the hover effect applies to links */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Match the card shadow */
}

/* Add spacing for the clickable text inside the card */
.projectLinkText {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  margin-top: 1rem;
}

.projectLinkText:hover {
  text-decoration: underline;
}
