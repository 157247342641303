/* Blog container */
.blogContainer {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 2rem 1rem;
  max-width: 800px; /* Limit text width for readability */
  margin: 0 auto;
  background-color: #f8f9fa; /* Light gray background */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  line-height: 1.8; /* Increased line spacing */
}

/* Blog title */
.title {
  font-family: 'Playfair Display', serif;
  font-size: 2.8rem; /* Slightly larger for prominence */
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

/* Description */
.description {
  font-size: 1.2rem;
  color: #555; /* Subtle color for secondary text */
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  line-height: 1.6; /* Good readability for secondary text */
}

/* Search container */
.searchContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; /* Increased spacing */
}

/* Search bar */
.searchBar {
  width: 60%; /* Slightly wider for ease of use */
  max-width: 400px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.searchBar:focus {
  outline: none;
  border-color: #0072ff;
}

/* Blog posts */
.postsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem; /* Increased gap for clearer separation */
}

/* Individual post */
.post {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px; /* Rounded corners for modern design */
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.post:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Post title */
.postTitle {
  font-family: 'Playfair Display', serif;
  font-size: 1.8rem; /* Slightly larger for emphasis */
  color: #222;
  margin-bottom: 0.5rem;
}

/* Post date */
.date {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 1rem;
}

/* Content preview */
.contentPreview {
  font-size: 1.1rem; /* Larger for better readability */
  color: #555;
  line-height: 1.8;
}

/* Post image */
.postImage {
  width: 100%;
  height: auto;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Toggle button */
.toggleButton {
  background-color: #0072ff;
  color: white;
  border: none;
  padding: 0.6rem 1.2rem; /* Slightly larger for usability */
  border-radius: 6px;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggleButton:hover {
  background-color: #0056b3;
}

/* Dek (subtitle or description) */
.dek {
  font-size: 1.1rem;
  color: #666;
  margin: 0.5rem 0;
  line-height: 1.6;
}
